import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import { getToken } from '@/utils/auth'
import router from '../router/router'
import ElementUI from 'element-ui'
// Vue.prototype.$message = Message

axios.defaults.timeout = 10000;
//http request请求拦截器
axios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        let err = JSON.stringify(error)
        console.log(err)
        return Promise.reject(error);
    }
);

//http response 响应拦截器
axios.interceptors.response.use(
    response => {       	
		if(response.data.code == 650){
			router.push('/login')			
			localStorage.removeItem('userInfo')
		}
        return response;
    },
    error => {
        let err = JSON.stringify(error)
        console.log(err)		
        return Promise.reject(error)
    }
)

export const httpPost = (url,param={},headers) => {
    let obj = {
        ...headers,
        'Content-Type':'application/x-www-form-urlencoded',
		Authorization: getToken()
    }

    return new Promise((resolve,reject)=>{
        axios({
            method: 'post',
            url,
            data: qs.stringify(param),
			// data: JSON.stringify(param),
            headers: obj,
            withCredentials: true
        })
        .then(res => {
            if(res.data.code == 650){
                var name = router.history.current.name
                if(name !== 'login'){
                    router.push('/login')
                }
            }
            resolve(res)
        }).catch(err => {
            reject(err)
        });
    });
}

export const httpGet = (url,param={},headers) => {
    let obj = {
        ...headers,
        'Content-Type':'application/x-www-form-urlencoded',
		Authorization: getToken()
    }
  
    return new Promise((resolve,reject)=>{
        axios({
            method: 'get',
            params:param,
            url: url,
            headers: obj,
            withCredentials: true
        }).then(res => {
            if(res.data.code == 650){
                var name = router.history.current.name
                if(name !== 'login'){
                    router.push('/login')
                }
            }
            resolve(res)
        }).catch(err => {
            reject(err)
        });
    });
}

export const httpPostUpload = (url,param={},headers) => {
    let obj = {
        ...headers,
        'Content-Type':'multipart/form-data',
		Authorization: getToken()
    }

    return new Promise((resolve,reject)=>{
        axios({
            method: 'post',
            url,
            data: param,
            headers: obj,
            withCredentials: true
        })
        .then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        });
    });
}
